import { App as BasicApp, sync, translation, computed, action, getLanguageBy, hasPermission } from "@circle/gestalt-app";
import { DatePicker } from "./types/DatePicker";
import { min, isUuid } from "./helper/helper";
import { getConfigOfCluster, getClusterType } from "./helper/cluster";
import { createBrowserHistory } from "history";
import { dateToString } from "./helper/date";
import { sort } from "./helper/sort";
import { search } from "./helper/search";

const getPath = x => {
    const pathParts = x.split("/");

    if(x.startsWith("/dashboard") || x.startsWith("/history")) return `/${pathParts[1]}`;

    return `/${pathParts[1]}/${pathParts[2]}`;
};

const createQueryString = filters => {
    const format = value => typeof value === "string" ? value.replaceAll("+", "%2B") : value;

    return Object.keys(filters).reduce((dest, val) => dest.concat(`${val}=${filters[val] instanceof Array ? `[${filters[val]}]&` : `${format(filters[val])}&`}`), "").slice(0, -1);
};

class App extends BasicApp {
    plants = sync("plants");
    clusters = sync("clusters");
    plant_elements = sync("plant_elements"); // eslint-disable-line camelcase
    events = sync("events");
    manufacturers = sync("manufacturers");
    target_settings = sync("target_settings"); // eslint-disable-line camelcase
    licenses = sync("licenses", {
        readonly: true
    });
    productivity_favorites = sync("productivity_favorites"); // eslint-disable-line camelcase
    productivity_target_settings = sync("productivity_target_settings"); // eslint-disable-line camelcase

    translations = translation({
        de: "/translations/texts_de.yml",
        en: "/translations/texts_en.yml"
    });

    static state = {
        history:                      createBrowserHistory(),
        language:                     getLanguageBy(window.config.languages.map(x => x.value)),
        plants:                       [],
        clusters:                     [], // eslint-disable-next-line camelcase
        plant_elements:               [],
        events:                       [],
        licenses:                     [],
        target_settings:              [], // eslint-disable-line camelcase
        manufacturers:                [],
        productivity_favorites:       [], // eslint-disable-line camelcase
        productivity_target_settings: [], // eslint-disable-line camelcase
        translations:                 {},
        default:                      window.config.defaultLanguage,
        languages:                    window.config.languages,
        selectedCluster:              "",
        selectedPlantElement:         "",
        overview:                     [],
        performanceContent:           {},
        durationContent:              {},
        eventsContent:                { list: [], overview: {} },
        runtimeOverview:              {},
        performanceOverview:          {},
        eventsStatisticsTable:        [],
        clusterRuntimeOverview:       {},
        clusterElements:              [],
        runtimeData:                  {},
        fullScreenWidget:             false,
        filter:                       { selected: "name", order: "asc", language: getLanguageBy(window.config.languages.map(x => x.value)) },
        calendar:                     DatePicker.of("yesterday"),
        queryOptions:                 {
            orderKey: "name",
            order:    "asc",
            calendar: DatePicker.of("yesterday"),
            range:    "yesterday"
        },
        search:         "",
        searchElements: "",
        counter:        {
            global:  0,
            monitor: 0
        },
        clock:    Date.now(),
        ordering: {
            order:  "desc",
            column: "product"
        },
        sortings: {
            events: {
                isOrderAsc: true,
                property:   ""
            },
            clusters: {
                isOrderAsc: true,
                property:   ""
            },
            plantElements: {
                isOrderAsc: true,
                property:   ""
            },
            settings: {
                isOrderAsc: true,
                property:   ""
            },
            clusterTable: {
                isOrderAsc: true,
                property:   ""
            },
            clusterElements: {
                isOrderAsc: true,
                property:   ""
            },
            products: {
                isOrderAsc: true,
                property:   ""
            },
            clusterAdministration: {
                isOrderAsc: true,
                property:   ""
            }
        },
        loadingViews: {
            performanceContent:     true,
            durationContent:        true,
            eventsContent:          true,
            eventsGraph:            true,
            eventsStatisticsTable:  true,
            clusterElements:        true,
            performanceOverview:    true,
            runtimeOverview:        true,
            clusterRuntimeOverview: true
        },
        metadata: {
            eventsContent: {
                limit: 10,
                page:  0,
                count: 10
            },
            detail: {
                limit: 10,
                page:  0,
                count: 10
            }
        }
    };

    // --- computations ---

    sortedPlants = computed({
        plants:    ["fullPlants"],
        translate: ["translate"],
        options:   ["queryOptions"],
        overview:  ["overview"]
    }, data => {
        if(!data.plants || !data.options || !data.overview) return [];

        const plants = data.plants.map(plant => {
            const overviewData = data.overview.find(x => x.plantId === plant.id);

            const perf = overviewData ? overviewData.performance.absolute : { target: 0 };

            return {
                ...plant,
                ...overviewData,
                perf: perf.target > 0 ? (perf.current / perf.target) : 0
            };
        });

        const sortedPlants = sort({
            getter:   x => data.translate(x[data.options.orderKey]),
            items:    plants,
            ordering: data.options.order
        });

        return sortedPlants.map(x => data.plants.find(y => y.id === x.id));
    });

    licensedPlants = computed({
        licenses: ["licenses"],
        plants:   ["plants"],
        user:     ["user"]
    }, data => {
        if(hasPermission(data.user, "SKIP_LICENSE_CHECK")) return data.plants;

        const plantIds = data.licenses
            .reduce((dest, elem) => dest.concat(elem.licenses.filter(x => x.appId === "performance-monitor")
            .filter(x => new Date(x.expiryDate) > new Date() || x.expiryDate !== null)
            .map(x => x.plantId)), []);

        return [...new Set(plantIds)]
            .filter(elem => elem)
            .map(x => data.plants.find(elem => elem.id === x))
            .filter(x => x);
    });

    sortedClusters = computed({
        clusters:  ["clusters"],
        translate: ["translate"],
        sorting:   ["sortings", "clusters"]
    }, data => {
        if(!data.clusters) return [];

        return sort({
            getter:   x => data.translate(x[data.sorting.property]),
            items:    data.clusters,
            ordering: data.sorting.isOrderAsc ? "asc" : "desc"
        });
    });

    sortedPlantElementsDropdown = computed({
        plantElements: ["plant_elements"],
        translate:     ["translate"]
    }, data => {
        if(!data.plantElements) return [];

        return sort({
            getter:   x => data.translate(x.name),
            items:    data.plantElements,
            property: "name",
            ordering: "asc"
        });
    });

    sortedPlantElements = computed({
        plantElements: ["sortedPlantElementsDropdown"],
        translate:     ["translate"]
    }, data => {
        if(!data.plantElements) return [];

        return sort({
            getter:   x => data.translate(x.name),
            items:    data.plantElements,
            ordering: "desc"
        });
    });

    sortedDashboardsFilter = computed({
        dashboards: ["dashboards"],
        translate:  ["translate"]
    }, data => {
        if(!data.dashboards) return [];

        return sort({
            getter:   x => data.translate(x.name),
            items:    data.dashboards,
            ordering: "asc"
        });
    });

    sortedProducts = computed({
        products:  ["clusterRuntimeOverview", "productTypes"],
        sorting:   ["sortings", "products"],
        translate: ["translate"]
    }, data => {
        if(!data.products) return [];

        return sort({
            getter:   x => data.translate(x[data.sorting.property]),
            items:    data.products,
            property: data.sorting.property,
            ordering: data.sorting.isOrderAsc ? "asc" : "desc"
        });
    });

    sortedTargetSettings = computed({
        targetSettings: ["target_settings"],
        translate:      ["translate"],
        sorting:        ["sortings", "settings"]
    }, data => {
        if(!data.targetSettings) return [];

        return sort({
            getter:   x => data.translate(x[data.sorting.property]),
            items:    data.targetSettings,
            property: data.sorting.property,
            ordering: data.sorting.isOrderAsc ? "asc" : "desc"
        });
    });

    settingsPlants = computed({
        plants:    ["licensedPlants"],
        translate: ["translate"],
        search:    ["search"]
    }, data => {
        if(!data.plants) return [];

        return search((data.plants || []).map(x => ({
            value: `${data.translate(x.name)}`,
            key:   x.id
        })), data.search).map(x => data.plants.find(plant => plant.id === x.key));
    });

    observedClusters = computed({
        plants:        ["licensedPlants"],
        clusters:      ["clusters"],
        plantElements: ["plant_elements"],
        favorites:     ["productivity_favorites"],
        translate:     ["translate"],
        search:        ["search"]
    }, data => {
        if(!data.plants || !data.clusters || !data.favorites) return [];
        const observedContent = data.plants.map(plant => ({
            plant,
            clusters: search((data.clusters || []).map(x => ({
                value: `${data.translate(x.name)}`,
                key:   x.id
            })), data.search)
            .map(x => data.clusters?.find(cluster => cluster.id === x.key)) // eslint-disable-line max-nested-callbacks
            .filter(cluster => cluster.plantId === plant.id)
            .filter(cluster => data.favorites.some(favorite => favorite.clusterId === cluster.id)).map(x => Object.assign({ // eslint-disable-line max-nested-callbacks
                ...x,
                type: "cluster"
            })),
            plantElements: search((data.plantElements || []).map(x => ({
                value: `${data.translate(x.name)}`,
                key:   x.id
            })), data.search)
            .map(x => data.plantElements?.find(element => element.id === x.key)) // eslint-disable-line max-nested-callbacks
            .filter(element => element.plantId === plant.id)
            .filter(element => data.favorites.some(favorite => favorite.plantElementId === element.id)).map(x => Object.assign({ // eslint-disable-line max-nested-callbacks
                ...x,
                type: "plantElement"
            }))
        }));

        const result = observedContent.filter(x => (x.clusters.length >= 1 || x.plantElements.length >= 1));

        return result;
    });

    allLanguages = computed({
        languages: ["languages"],
        translate: ["translate"]
    }, data => {
        const translate = data.translate ? data.translate : elem => elem;

        return data.languages.map(elem => ({
            value: elem.value,
            label: translate(elem.value)
        }));
    });

    fullPlants = computed({
        plants:        ["licensedPlants"],
        manufacturers: ["manufacturers"]
    }, data => {
        if(!data.plants || !data.manufacturers) return [];

        return data.plants.map(x => Object.assign({}, x, {
            manufacturer: data.manufacturers.find(y => y.id === x.manufacturerId)
        }));
    });

    joinedClusters = computed({
        plantElements: ["sortedPlantElements"],
        clusters:      ["sortedClusters"]
    }, data => {
        return !data.clusters || !data.plantElements ? [] : data.clusters
            .map(x => ({
                ...x,
                plantElements: x.plantElements.map(plantElementRelation => ({
                    ...plantElementRelation,
                    name: data.plantElements?.find(elem => elem.id === plantElementRelation.plant_element_id)?.name, // eslint-disable-line max-nested-callbacks
                    type: data.plantElements?.find(elem => elem.id === plantElementRelation.plant_element_id)?.type // eslint-disable-line max-nested-callbacks
                }))
            })).map(x => ({
                ...x,
                config: getConfigOfCluster(x)
            }));
    });

    headerPieGraph = computed({
        overview: ["runtimeData"]
    }, data => {
        if(!data.overview) return {};

        const content = data.overview;

        const pieData = ["used", "errors", "organizationals"].map(name => ({
            name:    name,
            value:   content.runtime ? content.runtime.states[name].duration : null,
            portion: content.runtime ? content.runtime.states[name].portion : null
        }));

        const subData = ["maintenance", "setup", "notReady", "blocked", "standstill"].map(name => ({
            name:    name,
            value:   content.runtime ? content.runtime.states.organizationals.states[name]?.duration : null,
            portion: content.runtime ? content.runtime.states.organizationals.states[name]?.portion : null
        }));

        return {
            pieData,
            subData,
            overviewData: content.runtime
        };
    });

    clustersTable = computed({
        overview:  ["runtimeOverview"],
        sorting:   ["sortings", "clusters"],
        translate: ["translate"]
    }, data => {
        if(!data.overview && !data.overview.items) return [];

        const content = data.overview;
        const getType = item => {
            if(item.clusterId)      return "cluster";
            if(item.plantElementId) return "plantElement";

            return "cluster";
        };

        const clustersData = content.items?.map(item => ({
            clusterId:      item.clusterId || null,
            plantElementId: item.plantElementId || null,
            type:           getType(item),
            name:           item.name,
            items:          ["error", "notReady", "blocked", "standstill", "used", "turnedOff"].map(name => ({
                name:     name,
                duration: item.durations[name]?.duration || null,
                portion:  item.durations[name]?.portion || null
            })),
            elements: item.elements
        })) || [];

        const clustersToSort = clustersData?.filter(x => (x.clusterId || x.plantElementId));

        const sortedClusters = sort({
            items:    clustersToSort,
            ordering: data.sorting.isOrderAsc ? "asc" : "desc",
            getter:   data.sorting.property === "name" ? x => data.translate(x.name) : item => item.items.find(x => x.name === data.sorting.property)?.duration ?? 0
        });

        return sortedClusters;
    });

    sortedClusterAdminList = computed({
        clusters:  ["clustersOfPlant"],
        translate: ["translate"],
        sorting:   ["sortings", "clusterAdministration"]
    }, data => {
        if(!data.clusters) return [];

        const formats = {
            name: x => data.translate(x.name)
        };

        return sort({
            items:    data.clusters,
            ordering: data.sorting.isOrderAsc ? "asc" : "desc",
            getter:   formats[data.sorting.property] ?? (x => x[data.sorting.property])
        });
    });

    sortedClusterElements = computed({
        clusters:  ["performanceOverview", "clusters"],
        translate: ["translate"],
        sorting:   ["sortings", "clusterElements"]
    }, data => {
        if(!data.clusters) return [];

        const formats = {
            produced: x => x.produced.current,
            name:     x => data.translate(x.name)
        };

        const getType = item => {
            if(item.clusterId)      return "cluster";
            if(item.plantElementId) return "plantElement";

            return "cluster";
        };

        const clusters = data.clusters.map(y => Object.assign({
            ...y,
            type: getType(y)
        }));
        const filtered = clusters.filter(x => x.performance > 0 || x.produced.current > 0);

        return sort({
            items:    filtered,
            ordering: data.sorting.isOrderAsc ? "asc" : "desc",
            getter:   formats[data.sorting.property] ?? (x => x[data.sorting.property])
        });
    });

    isFavorite = computed({
        plantElement:    ["selectedPlantElement"],
        selectedCluster: ["selectedCluster"],
        favorites:       ["productivity_favorites"]
    }, data => {
        if(data.plantElement?.id?.length) {
            const favoriteFind =  data.favorites.filter(x =>
                x.plantElementId === data.plantElement?.id &&
                x.plantId === data.plantElement.plantId).length > 0;

            return favoriteFind;
        }

        const favoriteFind =  data.favorites.filter(x =>
            x.clusterId === data.selectedCluster?.id &&
            x.plantId === data.selectedCluster.plantId).length > 0;

        return favoriteFind;
    });

    clusterFavoriteView = computed({
        plantElement:    ["selectedPlantElement"],
        selectedCluster: ["selectedCluster"],
        favorites:       ["productivity_favorites"],
        content:         ["clusterRuntimeOverview"]
    }, data => {
        if(!data.selectedCluster || !data.favorites || !data.content) return {};

        const isFavorite = data.favorites.filter(x =>
            (x.clusterId === data.selectedCluster?.id || x.plantElementId === data.plantElement?.id) &&
            (x.plantId === (data.selectedCluster.plantId ?? data.plantElement?.plantId))).length > 0;

        return {
            ...data.content,
            isFavorite: isFavorite
        };
    });

    clustersOfPlant = computed({
        selectedPlant: ["selectedPlant"],
        clusters:      ["joinedClusters"]
    }, data => {
        if(!data.clusters || !data.selectedPlant) return [];

        const filteredClusters = data.clusters.filter(cluster => cluster.plantId === data.selectedPlant.id);

        return filteredClusters;
    });

    joinedPlantElementSettings = computed({
        plantElements:  ["plant_elements"],
        targetSettings: ["productivity_target_settings"]
    }, data => {
        if(!data.plantElements || !data.targetSettings) return [];

        const getIsReferenceObject = (plantElement, targetSetting = { reference: null }) => {
            return typeof plantElement.isPlantReference !== "boolean" ? targetSetting.isPlantReference : plantElement.isPlantReference;
        };

        const getIsRefObjDisabled = plantElement => {
            return typeof plantElement.isPlantReference === "boolean";
        };

        return data.plantElements.map(x => {
            const targetSetting = (data.targetSettings || []).find(setting => setting.plantElementId === x.id) || {
                target:    null,
                reference: null
            };

            return {
                id:                x.id,
                plantId:           x.plantId,
                name:              x.name,
                status:            x.status,
                type:              x.type,
                isReferenceObject: getIsReferenceObject(x, targetSetting),
                isRefObjDisabled:  getIsRefObjDisabled(x),
                reference:         targetSetting.reference,
                target:            targetSetting.target,
                targetSettingsId:  targetSetting.id,
                createdAt:         targetSetting.createdAt
            };
        });
    });

    sortedPlantElementsSettings = computed({
        plantElements: ["joinedPlantElementSettings"],
        translate:     ["translate"],
        sorting:       ["sortings", "plantElements"]
    }, data => {
        if(!data.plantElements) return [];

        return sort({
            getter:   x => data.sorting.property === "name" ? data.translate(x[data.sorting.property]) : x[data.sorting.property],
            items:    data.plantElements,
            ordering: data.sorting.isOrderAsc ? "asc" : "desc"
        });
    });

    plantElementsOfPlant = computed({
        selectedPlant: ["selectedPlant"],
        translate:     ["translate"],
        plantElements: ["sortedPlantElementsSettings"],
        search:        ["searchElements"]
    }, data => {
        if(!data.plantElements || !data.selectedPlant) return [];

        const plantElements = data.plantElements.filter(element => element.plantId === data.selectedPlant.id);

        return search(plantElements.map(x => ({
            value: data.translate(x.name),
            key:   x.id
        })), data.search).map(x => plantElements.find(event => event.id === x.key));
    });

    plantElementsList = computed({
        selectedPlant: ["selectedPlant"],
        translate:     ["translate"],
        plantElements: ["joinedPlantElementSettings"]
    }, data => {
        if(!data.plantElements || !data.selectedPlant) return [];

        const plantElements = data.plantElements.filter(element => element.plantId === data.selectedPlant.id);

        return plantElements;
    });

    referenceElements = computed({
        plantElements: ["plantElementsOfPlant"]
    }, data => {
        if(!data.plantElements) return [];

        return data.plantElements.filter(x => x.isReferenceObject);
    });

    // --- actions ---

    changeLanguage = action(value => {
        const language = value ? value : this.state.get("locale");

        this.state.select("language").set(language);
        this.state.commit();
    });

    search = action(value => {
        this.state.select("search").set(value);

        this.state.commit();
    });

    searchElements = action(value => {
        this.state.select("searchElements").set(value);

        this.state.commit();
    });

    setQueryOptions = action(data => {
        const previous = this.state.get("queryOptions");

        this.state.select("queryOptions").set({
            ...previous,
            ...data
        });

        this.state.commit();
    });

    applyQuery = action((options, keys) => {
        const optionKeys    = keys ?? [...new URLSearchParams(window.location.search).keys()];
        const selectedPlant = this.state.get("selectedPlant");
        const plantId       = isUuid(options.selectedPlant?.id) ? options.selectedPlant?.id : selectedPlant?.id;
        const content       = {
            startTime: `[${options.calendar.from.getTime() / 1000},${options.calendar.until.getTime() / 1000}]`,
            ...["range"].reduce((dest, x) => options[x] ? ({ ...dest, [x]: options[x] }) : dest, {}),
            order:     options.order,
            orderKey:  options.orderKey
        };

        if(optionKeys.length === 0) return this.redirect(plantId);

        const queryString = createQueryString(optionKeys.reduce((dest, x) => ({ ...dest, [x]: content[x] }), {}));

        return this.redirect(plantId, queryString);
    });

    redirect = (plantId, queryString) => {
        const [, main, prevPlantId, ...additional] = window.location.pathname.split("/");

        const plant = plantId ?? prevPlantId;

        if(plantId === prevPlantId && !queryString) return null;

        return this.trigger("open", `/${main}${plant ? `/${plant}` : ""}/${additional.join("/")}?${queryString ?? ""}`);
    };

    applyOption = action((type, value) => {
        const resolveValue = () => {
            if(type === "calendar" && value?.from && value?.until)
                return DatePicker.of([value.from, value.until]);


            return value;
        };

        const previous = this.state.get("queryOptions");
        const options  = {
            ...previous,
            [type]: resolveValue()
        };

        this.trigger("setQueryOptions", options);
        this.trigger("applyQuery", options);
        this.state.commit();

        return options;
    });

    applyPlant = action(plantId => {
        const plant    = this.state.get("fullPlants").find(x => x.id === plantId);
        const previous = this.state.get("queryOptions");
        const options  = {
            ...previous,
            selectedPlant: plant
        };

        this.state.select("selectedPlant").set(plant);
        this.state.select("filter").set(null);
        this.state.commit();

        this.trigger("setQueryOptions", options);
        this.trigger("applyQuery", options);
    });

    open = action(url => this.history(url));

    resetSelections = action(() => {
        this.state.select("selectedPlant").set(null);
        this.state.commit();
    });

    resetPlant = action(() => {
        this.state.select("selectedPlant").set(null);
        this.state.commit();
    });

    resetCluster = action(() => {
        this.trigger("applyCluster", null, null);
        this.state.commit();
    });

    resetPlantElement = action(() => {
        this.trigger("applyPlantElement", null);
        this.state.commit();
    });

    onOverviewPlantSelect = action(async(plant, filter) => {
        if(!plant) return;

        const options = await this.trigger("onPlantSelect", plant, filter);

        this.trigger("setQueryOptions", options);
        this.trigger("applyQuery", { ...options });
    });

    onSettingsPlantSelect = action(plant => {
        if(!plant) return;

        const finalPlant = plant && plant.id ? plant : this.state.get("licensedPlants").find(x => x.id === plant);
        const options = this.state.get("queryOptions");

        const additional = {
            ...options,
            selectedPlant: finalPlant
        };

        this.trigger("setQueryOptions", additional);
        this.state.select("selectedPlant").set(finalPlant);
        this.state.commit();

        this.history(`${getPath(window.location.pathname)}/${finalPlant ? finalPlant.id : ""}`);
    });

    onPlantSelect = action(plant => {
        const finalPlant = plant && plant.id ? plant : this.state.get("licensedPlants").find(x => x.id === plant);
        const previous   = this.state.get("queryOptions");

        this.state.select("selectedPlant").set(finalPlant);

        return {
            ...previous,
            selectedPlant: finalPlant
        };
    });

    setPlant = action(plant => {
        const finalPlant = plant && plant.id ? plant : this.state.get("licensedPlants").find(x => x.id === plant);

        this.state.select("selectedPlant").set(finalPlant);
        this.state.commit();
    });

    editFavorite = action(element => {
        const favorites = this.state.get("productivity_favorites");
        const index     = favorites.findIndex(favorite =>
            favorite.clusterId === element.clusterId &&
            favorite.plantId === element.plantId);

        const current = favorites[index];

        if(current) {
            this.state.unset(["productivity_favorites", index]);
            return this.state.commit();
        }

        delete element.id;

        this.state.select("productivity_favorites").push(element);
        return this.state.commit();
    });

    editFavoriteElement = action(element => {
        const favorites = this.state.get("productivity_favorites");
        const index     = favorites.findIndex(favorite =>
            favorite.plantElementId === element.plantElementId &&
            favorite.plantId === element.plantId);

        const current = favorites[index];

        if(current) {
            this.state.unset(["productivity_favorites", index]);
            return this.state.commit();
        }

        delete element.id;

        this.state.select("productivity_favorites").push(element);
        return this.state.commit();
    });

    createEvent = action(event => {
        delete event.id;

        const result = {
            ...event,
            createdAt: dateToString(new Date())
        };

        this.state.push(["events"], result);
        this.state.commit();
    });

    deleteCluster = action(id => {
        const idx = this.state
            .get("clusters")
            .findIndex(x => x.id === id);

        this.state.unset(["clusters", idx]);
        this.state.commit();
    });

    createCluster = action(cluster => {
        delete cluster.id;

        const result = {
            ...cluster,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
        };

        this.state.push(["clusters"], result);
        this.state.commit();
    });

    editCluster = action(cluster => {
        const currentIdx = this.state
            .get("clusters")
            .findIndex(x => x.id === cluster.id);

        const result = {
            ...cluster,
            updatedAt: new Date().toISOString()
        };

        this.state.set(["clusters", currentIdx], result);
        this.state.commit();
    });

    editPlantElement = action(elem => {
        const idx = this.state
        .get("productivity_target_settings")
        .findIndex(x => x.plantElementId === elem.id);

        if(idx !== -1) {
            const result = {
                id:               elem.targetSettingsId,
                plantElementId:   elem.id,
                reference:        elem.reference,
                target:           elem.target?.replace(",", "."),
                isPlantReference: elem.isReferenceObject,
                createdAt:        elem.createdAt,
                updatedAt:        new Date().toISOString()
            };

            this.state.set(["productivity_target_settings", idx], result);
            this.state.commit();
            return;
        }

        const result = {
            plantElementId:   elem.id,
            reference:        elem.reference,
            target:           elem.target?.replace(",", "."),
            isPlantReference: elem.isReferenceObject,
            createdAt:        new Date().toISOString(),
            updatedAt:        new Date().toISOString()
        };

        delete result.id;

        this.state.push(["productivity_target_settings"], result);
        this.state.commit();
        return;
    });

    editPlantElements = action(elements => {
        const plantElements = this.state.get("productivity_target_settings");

        elements.forEach(elemToUpdate => {
            const idx = plantElements.findIndex(x => x.plantElementId === elemToUpdate.id);

            if(idx !== -1) {
                const updatedElement = {
                    id:               elemToUpdate.targetSettingsId,
                    plantElementId:   elemToUpdate.id,
                    reference:        elemToUpdate.reference,
                    target:           elemToUpdate.target?.replace(",", "."),
                    isPlantReference: elemToUpdate.isReferenceObject,
                    createdAt:        elemToUpdate.createdAt,
                    updatedAt:        new Date().toISOString()
                };

                this.state.set(["productivity_target_settings", idx], updatedElement);
                this.state.commit();
                return;
            }

            const createdElement = {
                id:               elemToUpdate.targetSettingsId,
                plantElementId:   elemToUpdate.id,
                reference:        elemToUpdate.reference,
                target:           elemToUpdate.target?.replace(",", "."),
                isPlantReference: elemToUpdate.isReferenceObject,
                createdAt:        new Date().toISOString(),
                updatedAt:        new Date().toISOString()
            };

            delete createdElement.id;
            this.state.push(["productivity_target_settings"], createdElement);
            this.state.commit();

            return;
        });
    });

    deletePlantEle = action(id => {
        const idx = this.state
            .get("plant_elements")
            .findIndex(x => x.id === id);

        this.state.unset(["plant_elements", idx]);
        this.state.commit();
    });

    selectPlantElement = action(elem => {
        this.state.select("selectedPlantElement").set(elem);
        this.state.commit();
    });

    applyCluster = action((plantId, clusterId) => {
        const elements = this.state.get("plant_elements");
        const settings = this.state.get("productivity_target_settings");
        const _cluster = this.state.get("clusters").find(x => x.id === clusterId);

        const cluster = clusterId !== null && clusterId !== "null" ? { ..._cluster, plantElements: _cluster.plantElements.map(x => elements.find(y => y.id === x.plant_element_id)), measuringElements: _cluster.measuringElements.map(x => elements.find(y => y.id === x.plant_element_id)) } : {
            id:                null,
            name:              "element.overall",
            plantElements:     elements.filter(x => x.plantId === plantId),
            measuringElements: elements.filter(x => x.plantId === plantId && (x.isPlantReference || settings.find(y => y.id === x.id)?.isPlantReference))
        };

        const preparedCluster = {
            ...cluster,
            type: getClusterType(cluster)
        };

        const previous = this.state.get("queryOptions");
        const options  = {
            ...previous,
            selectedCluster: preparedCluster
        };

        this.state.select("selectedCluster").set(preparedCluster);
        this.trigger("setQueryOptions", options);
        this.trigger("applyQuery", options);
    });

    applyPlantElement = action(plantElementId => {
        const plantElement = this.state.get("plant_elements").find(x => x.id === plantElementId);
        const previous = this.state.get("queryOptions");
        const options  = {
            ...previous,
            selectedPlantElement: plantElement
        };

        this.state.select("selectedPlantElement").set(plantElement);
        this.trigger("setQueryOptions", options);
        this.trigger("applyQuery", options);
        this.state.commit();
    });

    clearMemory = action(() => {
        this.state.select("overview").set([]);
        this.state.select("performanceContent").set({});
        this.state.select("durationContent").set({});
        this.state.select("eventsContent").set({});
        this.state.select("runtimeOverview").set({});
        this.state.select("performanceOverview").set({});
        this.state.select("eventsStatisticsTable").set([]);
        this.state.select("clusterRuntimeOverview").set({});
        this.state.select("clusterElements").set([]);
        this.state.select("runtimeData").set({});
        this.state.select("metadata").set({
            eventsContent: {
                limit: 10,
                page:  0,
                count: 10
            }
        });

        this.state.select("loadingViews").set({
            performanceContent:     true,
            durationContent:        true,
            eventsContent:          true,
            eventsGraph:            true,
            eventsStatisticsTable:  true,
            clusterElements:        true,
            performanceOverview:    true,
            runtimeOverview:        true,
            clusterRuntimeOverview: true
        });

        this.state.commit();
    });

    reset = action(async() => {
        const previous = this.state.get("queryOptions");

        if(!previous.selectedPlant?.id) return;

        const yesterday = DatePicker.of("yesterday");

        const options  = await this.trigger("onPlantSelect", previous.selectedPlant?.id, {
            ...previous,
            clusters: []
        });
        const plant = ["/overview", "/favorite", "/admin"].reduce((dest, val) => dest || window.location.pathname.includes(val), false) ? null : options.selectedPlant;

        const additional  = {
            ...options,
            shift:         null,
            selectedPlant: plant
        };

        this.state.select("selectedPlant").set(plant);

        this.trigger("setQueryOptions", additional);
        this.trigger("onCalendarSelect", yesterday);
    });

    createSetting = action(setting => {
        delete setting.id;

        const result = {
            ...setting,
            createdAt: new Date().toISOString()
        };

        this.state.push(["target_settings"], result);
        this.state.commit();

        return this.history(`/admin/settings/${this.state.get("selectedPlant").id}`);
    });

    editSetting = action(setting => {
        const currentIdx = this.state
            .get("target_settings")
            .findIndex(x => x.id === setting.id);

        const result = {
            ...setting,
            updatedAt: new Date().toISOString()
        };

        this.state.set(["target_settings", currentIdx], result);
        this.state.commit();

        return this.history(`/admin/settings/${this.state.get("selectedPlant").id}`);
    });

    fetchOverviewItem = action(async({ plantId, reset }) => {
        if(reset) {
            this.state.select("overview").set([]);
            this.state.commit();
        }

        const { range, calendar } = this.state.get("queryOptions");

        const cal = range === "today" || range === "thisMonth" || range === "thisYear" ? DatePicker.of(range) : calendar;

        const url = `${window.config.backendUrl}/overview/${plantId}?timestamp=[${cal.from.getTime() / 1000},${cal.until.getTime() / 1000}]&range=${range}`;

        try {
            const result = await this.http("GET", url).then(x => x.json());

            const overviewItems = this.state.get("overview");

            this.state.select("overview").set(overviewItems.filter(x => x.plantId !== plantId).concat([result]));
            this.state.commit();
        } catch(error) {
            this.log.error(error);
            return;
        }
    });

    fetchPerformanceContent = action(async({ plantId, polling = false }) => { // eslint-disable-line max-statements
        if(!plantId) return;

        if(!polling) {
            this.state.select("loadingViews", "performanceContent").set(true);
            this.state.commit();
        }

        const { calendar, range } = this.state.get("queryOptions");

        const cal = range === "today" || range === "thisMonth" || range === "thisYear" ? DatePicker.of(range) : calendar;

        const url  = `${window.config.backendUrl}/overview/${plantId}/performance/metrics?timestamp=[${cal.from.getTime() / 1000},${cal.until.getTime() / 1000}]&range=${range}`;
        const url2 = `${window.config.backendUrl}/overview/${plantId}/performance/productTypes?page=1&pageSize=5&timestamp=[${cal.from.getTime() / 1000},${cal.until.getTime() / 1000}]&range=${range}}&orderKey=produced&order=desc`;
        const url3 = `${window.config.backendUrl}/status/${plantId}?timestamp=[${cal.from.getTime() / 1000},${cal.until.getTime() / 1000}]`;

        try {
            const [metrics, productTypes, status] = await Promise.all([
                this.http("GET", url).then(x => x.json()),
                this.http("GET", url2).then(x => x.json()),
                this.http("GET", url3).then(x => x.json())
            ]);

            const plant     = this.state.get("selectedPlant");
            const translate = this.state.get("translate");

            const workingShiftDays = plant.workingShifts.reduce((dest, shift) => dest.concat(shift.days.map(day => ({
                name: translate(shift.title),
                day:  day.day
            }))), []);

            const finalResult = {
                ...metrics,
                ...status,
                numberOfProductTypes: productTypes.totalItems,
                top5:                 productTypes.items,
                cycling:              [1, 2, 3, 4, 5, 6, 7].map(day => ({
                    day:    new Date(new Date(0).setDate((day === 7 ? 0 : day) + 4)).toLocaleString("de", { weekday: "long" }),
                    amount: workingShiftDays.filter(x => x.day === day).length,
                    shifts: workingShiftDays.filter(x => x.day === day).map(x => x.name)
                }))
            };

            this.state.select("performanceContent").set(finalResult);
            this.state.select("loadingViews", "performanceContent").set(false);
            this.state.commit();
        } catch(error) {
            this.state.select("loadingViews", "performanceContent").set(false);
            this.state.commit();
            this.log.error(error);
            return;
        }
    });

    fetchDurationContent = action(async({ plantId, polling = false }) => {
        if(!plantId) return;

        if(!polling) {
            this.state.select("loadingViews", "durationContent").set(true);
            this.state.commit();
        }

        const { calendar, range } = this.state.get("queryOptions");

        const cal = range === "today" || range === "thisMonth" || range === "thisYear" ? DatePicker.of(range) : calendar;

        const url = `${window.config.backendUrl}/overview/${plantId}/runtime?timestamp=[${cal.from.getTime() / 1000},${cal.until.getTime() / 1000}]&range=${range}`;

        try {
            const result = await this.http("GET", url).then(x => x.json());

            this.state.select("durationContent").set(result);
            this.state.select("loadingViews", "durationContent").set(false);
            this.state.commit();
        } catch(error) {
            this.state.select("loadingViews", "durationContent").set(false);
            this.state.commit();
            this.log.error(error);
            return;
        }
    });

    fetchEventsContentOverview = action(async({ plantId, polling = false }) => {
        if(!plantId) return;

        if(!polling) {
            this.state.select("loadingViews", "eventsContent").set(true);
            this.state.commit();
        }

        const { calendar, range }  = this.state.get("queryOptions");

        const cal = range === "today" || range === "thisMonth" || range === "thisYear" ? DatePicker.of(range) : calendar;

        const url = `${window.config.backendUrl}/events/${plantId}/overview?timestamp=[${cal.from.getTime() / 1000},${cal.until.getTime() / 1000}]&range=${range}`;

        try {
            const overview = await this.http("GET", url).then(x => x.json());

            this.state.select(["eventsContent", "overview"]).set(overview);
            this.state.select("loadingViews", "eventsContent").set(false);
            this.state.commit();
        } catch(error) {
            this.state.select("loadingViews", "eventsContent").set(false);
            this.state.commit();
            this.log.error(error);
            return;
        }
    });

    fetchEventsContentList = action(async({ plantId, orderKey = "name", order = "desc", reset = false }) => { // eslint-disable-line max-statements,complexity
        if(!plantId) return;

        if(reset) {
            this.state.select("loadingViews", "eventsTable").set(true);
            this.state.commit();
        }

        const { calendar, range } = this.state.get("queryOptions");

        const cal = range === "today" || range === "thisMonth" || range === "thisYear" ? DatePicker.of(range) : calendar;

        const current   = (this.state.get(["eventsContent", "list"]) || []);
        const _metadata = this.state.get(["metadata", "eventsContent"]);
        const metadata  = reset || !_metadata ? { count: 10, limit: 10, page: 0 } : _metadata;

        const url = `${window.config.backendUrl}/events/${plantId}/list?timestamp=[${cal.from.getTime() / 1000},${cal.until.getTime() / 1000}]&page=${metadata.page + 1}&pageSize=${metadata.limit}&range=${range}&orderKey=${orderKey}&order=${order}`;

        try {
            const list = await this.http("GET", url).then(x => x.json());

            this.state.select("eventsContent", "list").set(
                reset ? list : current.concat(list)
            );

            this.state.select("metadata", "eventsContent").set({
                page:  metadata.page + 1,
                limit: metadata.limit,
                count: list.length === metadata.limit ? metadata.count + metadata.limit : metadata.count - metadata.limit + list.length
            });

            this.state.select("loadingViews", "eventsTable").set(false);
            this.state.commit();
        } catch(error) {
            if(reset) {
                this.state.select("loadingViews", "eventsTable").set(false);
                this.state.commit();
            }
            this.log.error(error);
            return;
        }
    });

    fetchTopEventGroups = action(async({ plantId, orderKey = "count", order = "desc", polling = false }) => {
        if(!plantId) return;

        if(!polling) {
            this.state.select("loadingViews", "eventsOverview").set(true);
            this.state.commit();
        }

        const { calendar, range } = this.state.get("queryOptions");

        const cal = range === "today" || range === "thisMonth" || range === "thisYear" ? DatePicker.of(range) : calendar;

        const url = `${window.config.backendUrl}/events/${plantId}/groups?timestamp=[${cal.from.getTime() / 1000},${cal.until.getTime() / 1000}]&page=1&pageSize=5&range=${range}&orderKey=${orderKey}&order=${order}`;

        try {
            const list = await this.http("GET", url).then(x => x.json());

            this.state.select("eventsOverview", "content").set(list);

            this.state.select("loadingViews", "eventsOverview").set(false);
            this.state.commit();
        } catch(error) {
            this.state.select("loadingViews", "eventsOverview").set(false);
            this.state.commit();
            this.log.error(error);
            return;
        }
    });

    fetchEventGroups = action(async({ plantId, orderKey = "count", order = "desc", reset = false }) => { // eslint-disable-line max-statements,complexity
        if(!plantId) return;

        if(reset) {
            this.state.select("loadingViews", "eventsTable").set(true);
            this.state.commit();
        }

        const { calendar, range } = this.state.get("queryOptions");

        const cal = range === "today" || range === "thisMonth" || range === "thisYear" ? DatePicker.of(range) : calendar;

        const current   = (this.state.get(["eventsContent", "list"]) || []);
        const _metadata = this.state.get(["metadata", "eventsContent"]);
        const metadata  = reset || !_metadata ? { count: 10, limit: 10, page: 0 } : _metadata;

        const url = `${window.config.backendUrl}/events/${plantId}/groups?timestamp=[${cal.from.getTime() / 1000},${cal.until.getTime() / 1000}]&page=${metadata.page + 1}&pageSize=${metadata.limit}&range=${range}&orderKey=${orderKey}&order=${order}`;

        try {
            const list = await this.http("GET", url).then(x => x.json());

            this.state.select("eventsContent", "list").set(
                reset ? list.items : current.concat(list.items)
            );

            this.state.select("metadata", "eventsContent").set({
                page:  metadata.page + 1,
                limit: metadata.limit,
                count: list.totalItems
            });

            this.state.select("loadingViews", "eventsTable").set(false);
            this.state.commit();
        } catch(error) {
            if(reset) {
                this.state.select("loadingViews", "eventsTable").set(false);
                this.state.commit();
            }
            this.log.error(error);
            return;
        }
    });

    fetchEventsGraph = action(async({ plantId }) => {
        if(!plantId) return;

        this.state.select("loadingViews", "eventsGraph").set(true);
        this.state.commit();

        const { calendar, range } = this.state.get("queryOptions");
        const url = `${window.config.backendUrl}/events/${plantId}/course?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&range=${range}`;

        try {
            const result = await this.http("GET", url).then(x => x.json());

            this.state.select("eventsGraph").set(result);
            this.state.select("loadingViews", "eventsGraph").set(false);
            this.state.commit();
        } catch(error) {
            this.state.select("loadingViews", "eventsGraph").set(false);
            this.log.error(error);
            return;
        }
    });

    fetchEventsStatisticsTable = action(async({ plantId }) => {
        if(!plantId) return;

        this.state.select("loadingViews", "eventsStatisticsTable").set(true);
        this.state.commit();

        const { calendar, range } = this.state.get("queryOptions");
        const url      = `${window.config.backendUrl}/events/${plantId}/detail?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&range=${range}`;

        try {
            const result = await this.http("GET", url).then(x => x.json());

            this.state.select("eventsStatisticsTable").set(result);
            this.state.select("loadingViews", "eventsStatisticsTable").set(false);
            this.state.commit();
        } catch(error) {
            this.state.select("loadingViews", "eventsStatisticsTable").set(false);
            this.log.error(error);
            return;
        }
    });

    fetchRuntimeOverview = action(async({ plantId }) => { // eslint-disable-line max-statements
        if(!plantId) return;

        this.state.select("loadingViews", "runtimeOverview").set(true);
        const locale  = this.state.get("locale");

        const { calendar, range } = this.state.get("queryOptions");
        const overviewUrl     = `${window.config.backendUrl}/overview/${plantId}/runtime?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&locale=${locale}&range=${range}`;
        const interruptionUrl = `${window.config.backendUrl}/runtime/${plantId}/interruptions/groups?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&locale=${locale}&range=${range}`;
        const availabilityUrl = `${window.config.backendUrl}/runtime/${plantId}/availability?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&locale=${locale}&range=${range}`;

        try {
            const [overview, interruptions, availability] = await Promise.all([
                this.http("GET", overviewUrl).then(x => x.json()),
                this.http("GET", interruptionUrl).then(x => x.json()),
                this.http("GET", availabilityUrl).then(x => x.json())
            ]);

            const current = this.state.get("runtimeOverview");

            this.state.select("runtimeOverview").set({
                ...current,
                overview,
                availability,
                interruptions
            });

            this.state.select("runtimeData").set({ runtime: overview });
            this.state.select("loadingViews", "runtimeOverview").set(false);
            this.state.commit();
        } catch(error) {
            this.state.select("loadingViews", "runtimeOverview").set(false);
            this.log.error(error);
            return;
        }
    });

    fetchRuntimeClusters = action(async({ plantId, order = "desc", orderKey = "name" }) => {
        if(!plantId) return;

        this.state.select("loadingViews", "runtimeClusters").set(true);
        const locale = this.state.get("locale");

        const { calendar, range } = this.state.get("queryOptions");
        const itemsUrl = `${window.config.backendUrl}/runtime/${plantId}/clusters?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&locale=${locale}&order=${order}&orderKey=${orderKey}&range=${range}`;

        try {
            const items = await this.http("GET", itemsUrl).then(x => x.json());

            this.state.select(["runtimeOverview", "items"]).set(items);

            this.state.select("loadingViews", "runtimeClusters").set(false);
            this.state.commit();
        } catch(error) {
            this.state.select("loadingViews", "runtimeClusters").set(false);
            this.log.error(error);
            return;
        }
    });

    fetchFavoriteClusterRuntimeOverview = action(async({ plantId, clusterId }) => { // eslint-disable-line max-statements, id-length
        if(!plantId) return;

        this.state.select("loadingViews", "clusterRuntimeOverview").set(true);

        const locale     = this.state.get("locale");
        const additional = { clusterId };
        const appendix   = Object.keys(additional)
            .reduce((dest, elem) => ["null", "undefined"].indexOf(additional[elem]) === -1 ? dest.concat(`&${elem}=${additional[elem]}`) : dest, "");

        const { calendar, range }    = this.state.get("queryOptions");
        const runtimeOverviewUrl     = `${window.config.backendUrl}/overview/${plantId}/runtime?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&locale=${locale}&range=${range}${appendix}`;
        const performanceOverviewUrl = `${window.config.backendUrl}/overview/${plantId}/performance/metrics?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&locale=${locale}&range=${range}${appendix}`;
        const interruptionsUrl       = `${window.config.backendUrl}/runtime/${plantId}/interruptions?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&locale=${locale}&range=${range}${appendix}`;
        const performanceUrl         = `${window.config.backendUrl}/performance/${plantId}/course?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&locale=${locale}&range=${range}${appendix}`;
        const productsUrl            = `${window.config.backendUrl}/overview/${plantId}/performance/productTypes/detail?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&locale=${locale}&range=${range}${appendix}`;
        const availabilityUrl    = `${window.config.backendUrl}/runtime/${plantId}/availability?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&locale=${locale}&range=${range}${appendix}`;
        const speedCourseUrl         = `${window.config.backendUrl}/performance/${plantId}/speed/course?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&locale=${locale}&range=${range}${appendix}`;
        const eventsUrl              = `${window.config.backendUrl}/events/${plantId}/list?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&page=1&pageSize=10&range=${range}&orderKey=duration&locale=${locale}${appendix}`;

        try {
            const [runtime, performance, interruptions, performanceGraph, productTypes, availability, speedCourse, eventsList] = await Promise.all([
                this.http("GET", runtimeOverviewUrl).then(x => x.json()),
                this.http("GET", performanceOverviewUrl).then(x => x.json()),
                this.http("GET", interruptionsUrl).then(x => x.json()),
                this.http("GET", performanceUrl).then(x => x.json()),
                this.http("GET", productsUrl).then(x => x.json()),
                this.http("GET", availabilityUrl).then(x => x.json()),
                this.http("GET", speedCourseUrl).then(x => x.json()),
                this.http("GET", eventsUrl).then(x => x.json())
            ]);

            this.state.select("clusterRuntimeOverview").set({
                runtime,
                performance,
                interruptions,
                performanceGraph,
                productTypes,
                availability,
                speedCourse,
                eventsList
            });

            this.state.select("runtimeData").set({ runtime });
            this.state.select("loadingViews", "clusterRuntimeOverview").set(false);
            this.state.commit();
        } catch(error) {
            this.state.select("loadingViews", "clusterRuntimeOverview").set(false);
            this.log.error(error);
            return;
        }
    });

    fetchClusterRuntimeOverview = action(async({ plantId, clusterId, plantElementId }) => { // eslint-disable-line max-statements
        if(!plantId) return;

        this.state.select("loadingViews", "clusterRuntimeOverview").set(true);

        const locale     = this.state.get("locale");
        const additional = { clusterId, plantElementId };
        const appendix   = Object.keys(additional)
            .reduce((dest, elem) => ["null", "undefined"].indexOf(additional[elem]) === -1 ? dest.concat(`&${elem}=${additional[elem]}`) : dest, "");

        const { calendar, range }    = this.state.get("queryOptions");
        const runtimeOverviewUrl     = `${window.config.backendUrl}/overview/${plantId}/runtime?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&locale=${locale}&range=${range}${appendix}`;
        const performanceOverviewUrl = `${window.config.backendUrl}/overview/${plantId}/performance/metrics?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&locale=${locale}&range=${range}${appendix}`;
        const interruptionsUrl       = `${window.config.backendUrl}/runtime/${plantId}/interruptions?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&locale=${locale}&range=${range}${appendix}`;
        const performanceUrl         = `${window.config.backendUrl}/performance/${plantId}/course?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&locale=${locale}&range=${range}${appendix}`;
        const availabilityUrl    = `${window.config.backendUrl}/runtime/${plantId}/availability?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&locale=${locale}&range=${range}${appendix}`;
        const speedCourseUrl         = `${window.config.backendUrl}/performance/${plantId}/speed/course?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&locale=${locale}&range=${range}${appendix}`;

        try {
            const [runtime, performance, interruptions, performanceGraph, availability, speedCourse] = await Promise.all([
                this.http("GET", runtimeOverviewUrl).then(x => x.json()),
                this.http("GET", performanceOverviewUrl).then(x => x.json()),
                this.http("GET", interruptionsUrl).then(x => x.json()),
                this.http("GET", performanceUrl).then(x => x.json()),
                this.http("GET", availabilityUrl).then(x => x.json()),
                this.http("GET", speedCourseUrl).then(x => x.json())
            ]);

            const current = this.state.get("clusterRuntimeOverview");

            this.state.select("clusterRuntimeOverview").set({
                ...current,
                runtime,
                performance,
                interruptions,
                performanceGraph,
                availability,
                speedCourse
            });

            this.state.select("runtimeData").set({ runtime });
            this.state.select("loadingViews", "clusterRuntimeOverview").set(false);
            this.state.commit();
        } catch(error) {
            this.state.select("loadingViews", "clusterRuntimeOverview").set(false);
            this.log.error(error);
            return;
        }
    });

    fetchClusterProducts = action(async({ plantId, clusterId, plantElementId, order = "desc", orderKey = "name", reset = false }) => { // eslint-disable-line max-statements,complexity
        if(!plantId) return;

        if(reset) {
            this.state.select("loadingViews", "eventsContent").set(true);
            this.state.commit();
        }

        const locale     = this.state.get("locale");
        const additional = { clusterId, plantElementId };
        const appendix   = Object.keys(additional)
            .reduce((dest, elem) => ["null", "undefined"].indexOf(additional[elem]) === -1 ? dest.concat(`&${elem}=${additional[elem]}`) : dest, "");

        const { calendar, range } = this.state.get("queryOptions");
        const current   = (this.state.get(["clusterRuntimeOverview", "productTypes"]) || []);
        const _metadata = this.state.get(["metadata", "products"]);
        const metadata  = reset || !_metadata ? { count: 10, limit: 10, page: 0 } : _metadata;

        const productsUrl = `${window.config.backendUrl}/overview/${plantId}/performance/productTypes/detail?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&page=${metadata.page + 1}&pageSize=${metadata.limit}&locale=${locale}&order=${order}&orderKey=${orderKey}&range=${range}${appendix}`;

        try {
            const list = await this.http("GET", productsUrl).then(x => x.json());

            this.state.select(["clusterRuntimeOverview", "productTypes"]).set(
                reset ? list.items : current.concat(list.items)
            );

            this.state.select("metadata", "products").set({
                page:  metadata.page + 1,
                limit: metadata.limit,
                count: list.items.length === metadata.limit ? metadata.count + metadata.limit : metadata.count - metadata.limit + list.items.length
            });

            this.state.select("loadingViews", "clusterProducts").set(false);
            this.state.commit();
        } catch(error) {
            this.state.select("loadingViews", "clusterProducts").set(false);
            this.log.error(error);
            return;
        }
    });

    fetchEventsDetailList = action(async({ plantId, orderKey = "name", order = "desc", reset = false }) => { // eslint-disable-line max-statements,complexity
        if(!plantId) return;

        if(reset) {
            this.state.select("loadingViews", "runtimeEventList").set(true);
            this.state.commit();
        }

        const current   = (this.state.get(["clusterRuntimeOverview", "eventsList"]) || []);
        const { calendar, range } = this.state.get("queryOptions");
        const _metadata = this.state.get(["metadata", "detail"]);
        const metadata  = reset || !_metadata ? { count: 25, limit: 25, page: 0 } : _metadata;

        const url = `${window.config.backendUrl}/events/${plantId}/list?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&page=${metadata.page + 1}&pageSize=${metadata.limit}&range=${range}&orderKey=${orderKey}&order=${order}`;

        try {
            const list = await this.http("GET", url).then(x => x.json());

            this.state.select(["clusterRuntimeOverview", "eventsList"]).set(
                reset ? list : current.concat(list)
            );

            this.state.select(["metadata", "detail"]).set({
                page:  metadata.page + 1,
                limit: metadata.limit,
                count: list.length === metadata.limit ? metadata.count + metadata.limit : metadata.count - metadata.limit + list.length
            });

            if(reset) this.state.select("loadingViews", "runtimeEventList").set(false);

            this.state.commit();
        } catch(error) {
            this.state.select("loadingViews", "runtimeEventList").set(false);
            this.state.commit();
            this.log.error(error);
            return;
        }
    });

    fetchProductTypes = action(async({ plantId, order = "desc", orderKey = "name", reset = false }) => { // eslint-disable-line max-statements,complexity
        if(!plantId) return;

        if(reset) {
            this.state.select("loadingViews", "productTypes").set(true);
            this.state.commit();
        }

        const { calendar, range } = this.state.get("queryOptions");

        const locale    = this.state.get("locale");
        const current   = (this.state.get(["performanceOverview", "productTypes"]) || []);
        const _metadata = this.state.get(["metadata", "products"]);
        const metadata  = reset || !_metadata ? { count: 50, limit: 50, page: 0 } : _metadata;

        const url = `${window.config.backendUrl}/overview/${plantId}/performance/productTypes?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&page=${metadata.page + 1}&pageSize=${metadata.limit}&range=${range}&orderKey=${orderKey}&order=${order}&locale=${locale}`;

        try {
            const productTypes = await this.http("GET", url).then(x => x.json());

            this.state.select(["performanceOverview", "numberOfProductTypes"]).set(productTypes.totalItems);
            this.state.select(["performanceOverview", "productTypes"]).set(
                reset ? productTypes.items : current.concat(productTypes.items)
            );

            this.state.select(["metadata", "products"]).set({
                page:  metadata.page + 1,
                limit: metadata.limit,
                count: productTypes.items.length === metadata.limit ? metadata.count + metadata.limit : metadata.count - metadata.limit + productTypes.items.length
            });

            if(reset) {
                this.state.select("loadingViews", "productTypes").set(false);
                this.state.commit();
            }
            this.state.commit();
        } catch(error) {
            this.state.select("loadingViews", "productTypes").set(false);
            this.state.commit();

            this.log.error(error);
            return;
        }
    });

    fetchPerformanceList = action(async({ plantId, order = "desc", orderKey = "name" }) => {
        if(!plantId) return;

        this.state.select("loadingViews", "performanceList").set(true);

        const locale  = this.state.get("locale");

        const { calendar } = this.state.get("queryOptions");
        const url = `${window.config.backendUrl}/performance/${plantId}/clusters?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&locale=${locale}&order=${order}&orderKey=${orderKey}`;

        try {
            const clusters = await this.http("GET", url).then(x => x.json());

            this.state.select("performanceOverview", "clusters").set(clusters);
            this.state.select("loadingViews", "performanceList").set(false);
            this.state.commit();
        } catch(error) {
            this.state.select("loadingViews", "performanceList").set(false);
            this.state.commit();
            this.log.error(error);
            return;
        }
    });

    fetchPerformanceOverview = action(async({ plantId }) => { // eslint-disable-line max-statements
        if(!plantId) return;

        this.state.select("loadingViews", "performanceOverview").set(true);
        const locale  = this.state.get("locale");

        const { calendar, range } = this.state.get("queryOptions");
        const url  = `${window.config.backendUrl}/overview/${plantId}/performance/metrics?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&locale=${locale}&range=${range}`;
        const url2 = `${window.config.backendUrl}/performance/${plantId}/course?timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]&locale=${locale}&range=${range}`;

        try {
            const [metrics, graph] = await Promise.all([
                this.http("GET", url).then(x => x.json()),
                this.http("GET", url2).then(x => x.json())
            ]);

            const current   = this.state.get("performanceOverview");
            const plant     = this.state.get("selectedPlant");
            const translate = this.state.get("translate");

            const workingShiftDays = plant.workingShifts.reduce((dest, shift) => dest.concat(shift.days.map(day => ({
                name: translate(shift.title),
                day:  day.day
            }))), []);

            const finalResult = {
                ...current,
                ...metrics,
                cycling: [1, 2, 3, 4, 5, 6, 7].map(day => ({
                    day:    new Date(new Date(0).setDate((day === 7 ? 0 : day) + 4)).toLocaleString("de", { weekday: "long" }),
                    amount: workingShiftDays.filter(x => x.day === day).length,
                    shifts: workingShiftDays.filter(x => x.day === day).map(x => x.name)
                })),
                graph
            };

            this.state.select("performanceOverview").set(finalResult);
            this.state.select("loadingViews", "performanceOverview").set(false);
            this.state.commit();
        } catch(error) {
            this.state.select("loadingViews", "performanceOverview").set(false);
            this.log.error(error);
            return;
        }
    });

    onCalendarSelect = action(calendarElem => {
        this.state.select("calendar").set(calendarElem);
        this.state.commit();

        this.trigger("applyOption", "range", calendarElem.name);
        this.trigger("applyOption", "calendar", calendarElem);
    });

    changeLang = action(value => {
        const language = value ? value : this.state.get("locale");

        this.state.select("language").set(language);
        this.state.commit();
    });

    setClock = action(value => {
        this.state.select("clock").set(value);
        this.state.commit();
    });

    setSorting = action((key, newProperty) => {
        const { isOrderAsc, property } = this.state.get(["sortings", key]);
        const isDesc = isOrderAsc || property !== newProperty;

        this.state.select(["sortings", key]).set({
            isOrderAsc: !isDesc,
            property:   newProperty
        });
        this.state.commit();
    });

    // --- helper ---

    setCounter(type, summand = 1) {
        const cursor  = this.state.select("counter", type);
        const current = cursor.get();

        cursor.set(min(current + summand, 0));

        this.state.commit();
    }
}

export default App;
