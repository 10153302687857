import PropTypes from "prop-types";
import { resolveClassNames } from "palstek";

import styles from "./barGraph.module.scss";

const BarGraph = ({ ...props }) => {
    return (
        <div className={props.isSmall ? styles.isSmallProgressLine : styles.progressLine}>
            <div className={props.isSmall ? styles.isSmallContainer : styles.container}>
                <div className={resolveClassNames(styles.used, styles.progress)} style={{ height: `${props.data.used}%` }} />
                <div className={resolveClassNames(styles.organizationals, styles.progress)} style={{ height: `${props.data.organizationals}%` }} />
                <div className={resolveClassNames(styles.errors, styles.progress)} style={{ height: `${props.data.errors}%` }} />

                <div className={resolveClassNames(styles.progress, styles.producedParts)} style={{ height: `${props.data.producedParts}%` }} />
                <div className={resolveClassNames(styles.progress, styles.producedTarget)} style={{ height: `${props.data.target}%` }} />
            </div>
        </div>
    );
};

BarGraph.propTypes = {
    data:    PropTypes.object,
    isSmall: PropTypes.bool
};

export { BarGraph };
