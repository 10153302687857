import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { resolveClassNames } from "palstek";
import { Icon, OverflowContainer } from "@circle/kip-components";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";

import { InfoBox } from "../../generic/infoBox/InfoBox";
import { SortableHeader } from "../../generic/sortableHeader/SortableHeader";
import { MessageTypeProgress } from "../MessageTypeProgress";
import { BusyIndicator } from "../../BusyIndicator";

import styles from "./clustersTable.module.scss";
import { numberFormatter } from "../../../helper/numberFormatter";
import { usePrevious } from "../../../hooks/previous";

const ClustersTable = () => {
    const { translate }                     = useTranslation();
    const { trigger }                       = useTrigger();
    const [collapsedRows, setCollapsedRows] = useState({});
    const params = useParams();

    const { plant, isLoading, clustersTable, sorting, options } = useBranch({
        plant:         ["selectedPlant"],
        clustersTable: ["clustersTable"],
        isLoading:     ["loadingViews", "runtimeClusters"],
        sorting:       ["sortings", "clusters"],
        options:       ["queryOptions"]
    });

    const optionsPrev = usePrevious(options);

    const toggleRowCollapse = (e, key) => {
        e.stopPropagation();
        e.preventDefault();

        setCollapsedRows(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    const onClusterClick = elem => {
        if(elem.plantElementId) return trigger("applyPlantElement", elem);
        return trigger("applyCluster", params.plantId, elem.clusterId);
    };

    const isContentAvailable = clustersTable.length >= 1;

    const onSort = val => {
        trigger("setSorting", "clusters", val);
    };

    useEffect(() => {
        trigger("setSorting", "clusters", "name");
        trigger("fetchRuntimeClusters", { plantId: params.plantId, orderKey: "name", order: "desc", reset: true });
    }, []);

    useEffect(() => {
        if(!optionsPrev) return;

        trigger("fetchRuntimeClusters", { plantId: params.plantId, orderKey: sorting.property, order: sorting.isOrderAsc ? "asc" : "desc", reset: true });
    }, [options.calendar, options.range]);

    return (
        <div className={styles.mainContainer}>
            <InfoBox className="clustersBox">
                { isLoading && <BusyIndicator /> }
                {(!isLoading && isContentAvailable) &&
                    <div className={styles.table}>
                        <div className={styles.tableHeader}>
                            <SortableHeader
                                sorting={sorting}
                                onSort={onSort}
                                isVisible={true}
                                type="name"
                            />
                            <SortableHeader
                                sorting={sorting}
                                onSort={onSort}
                                isVisible={true}
                                type="error"
                            />
                            <SortableHeader
                                sorting={sorting}
                                onSort={onSort}
                                isVisible={true}
                                type="notReady"
                            />
                            <SortableHeader
                                sorting={sorting}
                                onSort={onSort}
                                isVisible={true}
                                type="blocked"
                            />
                            <SortableHeader
                                sorting={sorting}
                                onSort={onSort}
                                isVisible={true}
                                type="standstill"
                            />
                            <SortableHeader
                                sorting={sorting}
                                onSort={onSort}
                                isVisible={true}
                                type="used"
                            />
                            <SortableHeader
                                sorting={sorting}
                                onSort={onSort}
                                isVisible={true}
                                type="turnedOff"
                            />
                        </div>

                        <OverflowContainer className={styles.scrollView}>
                            {clustersTable.map((item, rowIndex) => ( // eslint-disable-line complexity
                                (item.clusterId || item.plantElementId) ?
                                    <div key={rowIndex}>
                                        <Link to={`/runtime/${plant?.id}/${item.clusterId}/${item.plantElementId}/${window.location.search}`} className={styles.titleLink}>
                                            <div
                                                key={rowIndex}
                                                className={resolveClassNames(
                                                    styles.row,
                                                    (!item.clusterId && !item.plantElementId) && styles.overall,
                                                    collapsedRows[rowIndex] && styles.collapsed
                                                )}
                                                onClick={() => onClusterClick(item.type === "plantElement" ? { plantElementId: item.plantElementId } : { clusterId: item.clusterId })}
                                            >
                                                <div className={resolveClassNames(styles.column, styles.tableTitleContainer)}>
                                                    <div className={styles.textEnd}>
                                                        <div>
                                                            <b>
                                                                <span title={translate(item.name)} className={styles.clampedTitle}>{translate(item.name)}</span>
                                                            </b>
                                                            {
                                                                item.elements.length > 0 &&
                                                                <span className={styles.elementsTitle}>{item.elements.length} {translate("detail.runtime.elements")}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {
                                                            item.elements.length > 0 &&
                                                            <Icon onClick={e => toggleRowCollapse(e, rowIndex)} className={resolveClassNames(styles.rowIcon, collapsedRows[rowIndex] && styles.isOpen)} _icon="ChevronDown" />
                                                        }
                                                        {
                                                            item.elements.length === 0 &&
                                                            <div className={styles.placeholder} />
                                                        }
                                                    </div>
                                                </div>
                                                {item.items.map((itemElement, index) => (
                                                    <div key={index} className={styles.column} data-columncount={1}>
                                                        <MessageTypeProgress
                                                            layer={1}
                                                            isCount={true}
                                                            duration={numberFormatter(itemElement.duration || 0, 999999)}
                                                            ratio={itemElement.portion}
                                                            tendency={itemElement.tendency}
                                                            type={itemElement.name}
                                                            hideBar={!item.clusterId && !item.plantElementId}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </Link>
                                        {item.elements?.map((element, subIndex) => (
                                            <Link key={subIndex} to={`/runtime/${plant?.id}/${element.clusterId}/${element.plantElementId}/${window.location.search}`} className={styles.titleLink}>
                                                <div
                                                    onClick={() => onClusterClick({ plantElementId: item.plantElementId })}
                                                    className={resolveClassNames(
                                                        styles.subTable,
                                                        !collapsedRows[rowIndex] && styles.collapsed
                                                    )}
                                                >
                                                    <div className={styles.subRow}>
                                                        <div className={styles.subColumn}><span>{element?.name}</span></div>
                                                        {["error", "notReady", "blocked", "standstill", "used", "turnedOff"].map((type, i) => (
                                                            <div key={i} className={styles.subColumn} data-columncount={1}>
                                                                <MessageTypeProgress
                                                                    layer={2}
                                                                    isCount={true}
                                                                    duration={element.durations[type]?.duration ? (numberFormatter(element.durations[type]?.duration, 999999) || 0) : 0}
                                                                    ratio={element.durations[type]?.portion ? (element.durations[type]?.portion || 0) : 0}
                                                                    tendency={null}
                                                                    type={type}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Link>
                                        ))}
                                    </div> :
                                    <span key={rowIndex} />
                            ))}
                        </OverflowContainer>
                    </div>
                }
                {
                    (!isLoading && !isContentAvailable) &&
                    <div>
                        <h4>{translate("overview.no.content")}</h4>
                    </div>
                }
            </InfoBox>
        </div>
    );
};

export { ClustersTable };
