import { resolveClassNames } from "palstek";
import PropTypes from "prop-types";
import { numberFormatter } from "../../../../helper/numberFormatter";

import styles from "./headersList.module.scss";

const HeadersList = ({ ...props }) => { // eslint-disable-line complexity
    return (
        <ul className={styles.list}>
            <li className={styles.listItem}>
                <div className={resolveClassNames(styles.circle, styles.producedParts)} />
                <div className={styles.listContainer}>
                    <div>
                        <div className={styles.statisticHeaderText}>
                            <h6>{props.data.produced && props.data.runtime > 0 ? numberFormatter(props.data.produced.absolute.current, 9999999) : "-"}</h6>
                            <span> / </span>
                            <span>{(props.data.produced.absolute.target && props.data.runtime > 0) ?
                                numberFormatter(props.data.produced.absolute.target, 9999999) :
                                "-"}</span>
                        </div>
                        <div className={styles.statisticSubtext}>
                            <small>{props.translate("overview.produced")} <b>{props.translate("overview.part")}</b></small>
                        </div>
                    </div>
                    <div>
                        <div className={styles.statisticHeaderText}>
                            <span>ø <b>{(props.data.produced && props.data.runtime > 0) ? numberFormatter(props.data.produced.perMinute.current, 9999999, true) : "-"}</b></span>
                            <span> / </span>
                            <span>{(props.data.produced.perMinute.target && props.data.runtime > 0) ?
                                numberFormatter(props.data.produced.perMinute.target, 9999999, true) :
                                "-"}</span>
                        </div>
                        <div className={styles.statisticSubtext}>
                            <small>{props.translate("overview.produced.parts")} <b>{props.translate("overview.per.minutes")}</b></small>
                        </div>
                    </div>
                    <div>
                        <div className={styles.statisticHeaderText}>
                            <span>ø <b>{props.data.produced && props.data.runtime > 0 ? props.data.produced.cycleTime : "-"}</b></span>
                            <small>{props.translate("title.seconds")}</small>
                        </div>
                        <div className={styles.statisticSubtext}>
                            <small>{props.translate("title.cycle.time")}</small>
                        </div>
                    </div>
                </div>
            </li>
            <li className={styles.listItem}>
                <div className={styles.placeholder}/>
                <div className={styles.listContainer}>
                    <div>
                        <div className={styles.statisticHeaderText}>
                            <h6>{props.data.sortedOut >= 0 && props.data.runtime > 0 ? numberFormatter(props.data.sortedOut, 9999999) : "-"}</h6>
                            <span>{props.translate("overview.part")}</span>
                        </div>
                        <div className={styles.statisticSubtext}>
                            <small>{props.translate("overview.sorted.out")}</small>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    );
};

HeadersList.propTypes = {
    data:      PropTypes.object,
    translate: PropTypes.func
};

export { HeadersList };
